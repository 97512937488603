<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Kode Barang
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="ovkCategory.code"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <!-- <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">Merek</div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="ovkCategory.note"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div> -->
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama Barang
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="ovkCategory.name"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Harga Beli
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <input
                  v-model="price"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Terakhir Update
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <input
                  class="uk-input"
                  :value="dateFormat(ovkCategory.updated_at)"
                  type="text"
                  disabled
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="margin-top: 32px;"
      class="uk-card uk-card-default kandang-card"
    >
      <TableOVK :price="dataPrice" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { dateParanjeCompleteString, currency } from '@/utils/formater'
import TableOVK from './table'

export default {
  components: {
    TableOVK
  },
  props: {},
  data() {
    return {
      dataPrice: {},
      price: '-'
    }
  },
  computed: {
    ...mapGetters({
      ovkCategory: 'ovkCategory/ovkCategory',
      ovkPrice: 'ovkCategory/ovkPrice',
      metaPrice: 'ovkCategory/metaPrice'
    })
  },
  watch: {
    async ovkCategory() {
      await this.getOvkPrice({...this.meta, item_code: this.ovkCategory.code})
      this.dataPrice = this.ovkPrice ? this.ovkPrice : {}
      const findData = this.ovkPrice.data ? this.ovkPrice.data.find(i => i.item_code == this.ovkCategory.code) : null
      this.price = findData ? currency(findData.price) : '-'
    },
    async metaPrice() {
      await this.getOvkPrice({...this.meta, item_code: this.ovkCategory.code})
    }
  },
  methods: {
    ...mapActions({
      getOvkPrice: 'ovkCategory/getOvkPrice'
    }),
    dateFormat(date) {
      return dateParanjeCompleteString(date)
    }
  }
}
</script>
