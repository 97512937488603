<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-2-3@s font-header">
        Detail "{{ ovkCategory.name }}"
      </div>
      <div class="uk-width-1-3@s">
        <bread-crumb :bread-crumb="breadcrumb" />
      </div>
    </div>

    <container />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Container from './Container'
import BreadCrumb from '@/components/globals/breadcrumb'

export default {
  components: {
    Container,
    BreadCrumb
  },
  data() {
    return {
      breadcrumb: [
        {
          link: '/admin/jenis-ovk',
          title: 'Jenis OVK'
        },
        {
          link: null,
          title: 'Detail'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ovkCategory: 'ovkCategory/ovkCategory'
    })
  },
  async mounted() {
    await this.getOvkCategoryDetail(this.$route.params.id)
  },
  methods: {
    ...mapActions({
      getOvkCategoryDetail: 'ovkCategory/getOvkCategoryDetail'
    })
  }
}
</script>
