<template>
  <div
    style="overflow: hidden;"
    class="uk-margin-top"
  >
    <div class="uk-overflow-auto">
      <table class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider">
        <thead class="thead-table-paranje">
          <tr>
            <th class="uk-table-expand">
              <span class="uk-text-bold">Tanggal Update</span>
            </th>
            <th class="uk-table-expand">
              <span class="uk-text-bold">Kode Barang</span>
            </th>
            <th class="uk-table-expand">
              <span class="uk-text-bold">Nama Barang</span>
            </th>
            <th class="uk-table-expand">
              <span class="uk-text-bold">Harga Beli</span>
            </th>
          </tr>
        </thead>
        <template v-if="!is_loading">
          <tbody v-if="price.meta ? price.meta.total_count>0 : false">
            <tr
              v-for="(cage, i) in price.data"
              :key="i"
            >
              <td>{{ formatDate(cage.updated_at) || '-' }}</td>
              <td>{{ cage.item_code || '-' }}</td>
              <td>{{ cage.item_name || '-' }}</td>
              <td>{{ currency(cage.price) || 0 }}</td>
            </tr>
          </tbody>
          <empty-table
            v-else
            :colspan="7"
          />
        </template>
        <template v-else>
          <loading-table :colspan="7" />
        </template>
      </table>
    </div>
    <pagination
      :total-data="price.meta ? price.meta.total_count : 0"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="meta.page"
      :current-limit="meta.limit"
    />
    <div class="back-btn uk-grid uk-margin-top">
      <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl uk-text-left">
        <button
          class="uk-button uk-button-default"
          type="button"
          @click="goToBack"
        >
          <img
            v-lazy="`${images}/icon/back.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Kembali
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Pagination from '@/components/globals/Pagination'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import { dateUtcParanjeString, currency } from '@/utils/formater'
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  components: {
    EmptyTable,
    LoadingTable,
    Pagination
  },
  props: {
    price: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      is_loading: true,
      images: PREFIX_IMAGE
    }
  },
  computed : {
    ...mapGetters({
      meta: 'ovkCategory/metaPrice'
    })
  },
  watch: {
    price() {
      this.is_loading = false
    }
  },
  methods: {
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    currency(num) {
      return currency(num)
    },
    ...mapMutations({
      setMeta: 'ovkCategory/SET_META_PRICE'
    }),
    changeLimit(e) {
      this.setMeta({
        ...this.meta,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.setMeta({
        ...this.meta,
        page: value
      })
    },
    goToBack() {
      window.location.href = '/admin/jenis-ovk'
    }
  }
}
</script>

<style scoped>
.back-btn {
  border-top: 1px solid #E0EBE6;
  padding-top: 30px;
}
</style>
